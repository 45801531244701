<template>
   <div class="col-12 px-0 mb-1" :class="togglePrecos ? 'main-container' : ''" ref="produto">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="w-max-content pe-12 d-none d-xl-block">
                  <img :src="produto.banner == null ? '' : produto.banner" class="rounded" alt="icon" @error="imageError" height="50" />
               </div>
               <div class="col-md text-center text-sm-start cursor-pointer" @click="togglePrecos = !togglePrecos;">
                  <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ produto.nome }}</h1>
                  <p class="font-10 mb-1 limitador-1 text-secondary">
                     <span><i class="far fa-hashtag font-9 me-1"></i>{{ produto.id }}</span>
                  </p>
                  <p class="font-12 mb-0 limitador-2">
                     <i class="far fa-barcode color-theme font-10 me-1"></i><strong class="subtitle me-1"> Código(s):</strong>
                     <span>{{ produto.codigos }}</span>
                  </p>
               </div>
            </div>
         </div>
      </div>

      <div class="sub-container" v-if="togglePrecos">
         <div class="row">
            <div class="col-12 mb-3">
               <h1 class="font-15 mb-0 mt-1"><i class="far fa-dollar-sign font-12 color-theme me-2"></i>Preços</h1>
            </div>

            <preco v-for="(preco, index) in produto.precos" :key="index" :preco="preco" :index="index" :showCusto="false" :showEcommerce="false" :showEdit="false" />

            <div class="col-12 my-5 text-center" v-if="produto.precos.length == 0">
               <i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
               <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum preço encontrado</h4>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import preco from '@/components/modelos/LojaPreco.vue'

export default {
   name: 'Produto',
   props: ['produto', 'index'],
   data : function () {
      return {
         togglePrecos: false
      }
   },
   components: {
      preco
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
   }
}

</script>