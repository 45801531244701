<template>
   <div class="col-xxl-6 ps-xxl-0">
      <div class="card mb-1" :class="index % 4 == 0 || index % 4 == 3 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-lg text-center text-lg-start">
                  <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ preco.nomeLoja }}</h1>
                  <p class="font-10 mb-1 limitador-1 text-secondary">
                     <span><i class="far fa-hashtag font-9 me-1"></i>{{ preco.id }}</span>
                     <span class="ms-3">
                        <i class="far fa-store font-9 me-1"></i>
                        <span>{{ preco.numeroLoja == null || String(preco.numeroLoja).trim().length == 0 ? 'Sem número' : preco.numeroLoja }}</span>
                     </span>
                     <span class="ms-3" v-if="preco.clubeDesconto">
                        <i class="far fa-info-circle font-9 me-1"></i><span>Produto faz parte de clube de desconto</span>
                     </span>
                  </p>
                  <p class="font-12 mb-0 limitador-1">
                     <i class="far fa-map-marker-alt color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Endereço:</strong>
                     <span> {{ preco.enderecoLoja == null || preco.enderecoLoja.rua == null ? 'Sem endereço' : (preco.enderecoLoja.rua +', '+ preco.enderecoLoja.numero +' - '+ preco.enderecoLoja.bairro) }}</span>
                  </p>
               </div>
               <div class="w-max-content text-center mx-auto mx-lg-0 mt-2 mt-lg-0" v-if="showCusto">
                  <label><i class="far fa-dollar-sign color-theme font-9 me-1"></i> Custo</label>
                  <input type="text" class="form-control input-sm wpx-115" v-model="preco.custo" :disabled="!dadosUsuario.usuarioPermissoes.includes('Produtos | Editar preços') || !showEdit" />
               </div>
               <div class="w-max-content text-center mx-auto mx-lg-0 mt-2 mt-lg-0" v-if="showEcommerce && dadosUsuario.usuarioModulos.includes('Ecommerce')">
                  <label><i class="far fa-desktop color-theme font-9 me-1"></i> Ecommerce</label>
                  <input type="text" class="form-control input-sm wpx-115" v-model="preco.precoSite" :disabled="!dadosUsuario.usuarioPermissoes.includes('Produtos | Editar preços') || !showEdit" />
               </div>
               <div class="w-max-content text-center mx-auto ms-lg-0 mt-2 mt-lg-0">
                  <label><i class="far fa-dollar-sign color-theme font-9 me-1"></i> Preço</label>
                  <input type="text" class="form-control input-sm wpx-115" v-model="preco.preco" :disabled="!dadosUsuario.usuarioPermissoes.includes('Produtos | Editar preços') || !showEdit" />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'LojaPreco',
   props: ['preco', 'index', 'showEdit', 'showCusto', 'showEcommerce'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario
      })
   },
   watch: {
      'preco.preco': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
            this.preco.preco = valor
         })
      },
      'preco.precoSite': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
            this.preco.precoSite = valor
         })
      },
      'preco.custo': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
            this.preco.custo = valor
         })
      }
   },
   mounted() {
      this.preco.preco = this.preco.preco == null ? '0.00' : parseFloat(this.preco.preco).toFixed(2)
      this.preco.precoSite = this.preco.precoSite == null ? '0.00' : parseFloat(this.preco.precoSite).toFixed(2)
      this.preco.custo = this.preco.custo == null ? '0.00' : parseFloat(this.preco.custo).toFixed(2)
   }
}

</script>